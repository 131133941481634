import { PER_PAGE } from '@/data'
import { matchfyClient } from '@/lib'

export const listSearchResults = async (params) => {
  const page = params?.pageParam || 1
  const limit = parseInt(params?.limit || PER_PAGE)
  const offset = parseInt((page - 1) * limit)
  const { query, type } = params

  const response = await matchfyClient.get(`/search`, {
    params: {
      limit,
      offset,
      query,
      type
    },
  })
  return response.data
}

const endpoints = {
  listSearchResults,
}

export default endpoints

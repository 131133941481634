import { PER_PAGE } from '@/data'
import { matchfyClient } from '@/lib'

const listBlockedUsers = async (params) => {
  const page = params?.pageParam || 1
  const limit = parseInt(params?.limit || PER_PAGE)
  const offset = parseInt((page - 1) * limit)

  const response = await matchfyClient.get(`/blocked-users/`, {
    params: { limit, offset },
  })

  return response.data
}

const checkIfUserIsBlocked = async (spotifyId) => {
  const response = await matchfyClient.get(`/blocked-users/check/${spotifyId}`)
  return response.data
}

const blockUser = async (spotifyId) => {
  const response = await matchfyClient.post(`/blocked-users/block/${spotifyId}`)
  return response.data
}

const unblockUser = async (spotifyId) => {
  const response = await matchfyClient.delete(`/blocked-users/unblock/${spotifyId}`)
  return response.data
}

const checkIfArtistIsBlocked = async (spotifyId) => {
  const response = await matchfyClient.get(`/blocked-users/check-artist/${spotifyId}`)
  return response.data
}

const endpoints = {
  blockUser,
  checkIfArtistIsBlocked,
  checkIfUserIsBlocked,
  listBlockedUsers,
  unblockUser
}

export default endpoints

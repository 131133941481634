import { matchfyClient } from '@/lib'

export const devUpdatePlan = async (data) => {
  const response = await matchfyClient.post('/dev/plan-switch', { ...data })
  return response.data
}

export const devRefreshUserCache = async () => {
  const response = await matchfyClient.post('/dev/refresh-user')
  return response.data
}

const endpoints = {
  devUpdatePlan,
  devRefreshUserCache,
}

export default endpoints

import { matchfyClient } from '@/lib'

export const uploadMedia = async (file) => {
  const response = await matchfyClient.post('/media/upload', { file, multipart: true })
  return response.data
}

const endpoints = {
  uploadMedia,
}

export default endpoints

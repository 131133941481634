import { PER_PAGE } from '@/data'
import { matchfyClient } from '@/lib'

export const addMagazineToQueue = async (magazineId, trackId) => {
  const response = await matchfyClient.post(
    `/magazine/${magazineId}/queue`,
    { trackId }
  )
  return response.data
}

export const isMagazineInQueue = async (magazineId, trackId) => {
  const response = await matchfyClient.get(
    `/magazine/${magazineId}/queue`,
    { params: { trackId } }
  )
  return response.data
}

export const listMagazines = async (params) => {
  const page = params?.pageParam || 1
  const limit = parseInt(params?.limit || PER_PAGE)
  const offset = parseInt((page - 1) * limit)
  const { genres, trackId, artistIds } = params

  const response = await matchfyClient.post(`/magazine`, {
    limit,
    offset,
    genres: genres,
    trackId,
    artistIds: artistIds
  })

  return response.data
}

const endpoints = {
  addMagazineToQueue,
  isMagazineInQueue,
  listMagazines
}

export default endpoints

import { PER_PAGE } from '@/data'
import { matchfyClient } from '@/lib'

// Artist
export const listPushedTracks = async (params) => {
  const page = params?.pageParam || 1
  const limit = parseInt(params?.limit || PER_PAGE)
  const offset = parseInt((page - 1) * limit)
  const { campaignId, vip } = params

  const query = {
    limit,
    offset,
  }

  if (campaignId) {
    query.campaignId = campaignId
  }

  if (vip) {
    query.vip = vip
  }

  const response = await matchfyClient.get(`/push/artist`, { params: query })
  return response.data
}

export const getPlaylistPushedIds = async (trackId) => {
  const response = await matchfyClient.get(`/push/artist/${trackId}/ids`)
  return response.data
}

export const listPushedPlaylistsByTrackId = async (params) => {
  const page = params?.pageParam || 1
  const limit = parseInt(params?.limit || PER_PAGE)
  const offset = parseInt((page - 1) * limit)
  const { trackId, status, handledBy } = params

  const response = await matchfyClient.get(`/push/artist/${trackId}`, {
    params: { limit, offset, status, handledBy },
  })
  return response.data
}

// Curator
export const acceptPush = async (playlistId, trackId, submitterId) => {
  const response = await matchfyClient.post(`/push/curator/${playlistId}/accept`, {
    trackId,
    submitterId,
  })
  return response.data
}

export const archivePush = async (playlistId, trackId) => {
  const response = await matchfyClient.post(`/push/curator/${playlistId}/archive`, {
    trackId,
  })
  return response.data
}

export const rejectPush = async (
  playlistId,
  trackId,
  submitterId,
  feedback
) => {
  const response = await matchfyClient.post(`/push/curator/${playlistId}/reject`, {
    feedback,
    trackId,
    submitterId,
  })
  return response.data
}

export const listPushedByPlaylistId = async (params) => {
  const page = params?.pageParam || 1
  const limit = parseInt(params?.limit || PER_PAGE)
  const offset = parseInt((page - 1) * limit)
  const { playlistId, handledBy, status, archived } = params

  const response = await matchfyClient.get(`/push/curator/${playlistId}`, {
    params: { limit, offset, handledBy, status, archived },
  })
  return response.data
}

export const undoPushAccept = async (playlistId, trackId, submitterId) => {
  const response = await matchfyClient.post(`/push/curator/${playlistId}/undo`, {
    trackId,
    submitterId,
  })
  return response.data
}

const endpoints = {
  acceptPush,
  archivePush,
  getPlaylistPushedIds,
  listPushedByPlaylistId,
  listPushedPlaylistsByTrackId,
  listPushedTracks,
  rejectPush,
  undoPushAccept
}

export default endpoints

import { matchfyClient } from '@/lib'

export const getMaintenance = async () => {
  const response = await matchfyClient.get('/maintenance')
  return response.data
}

const endpoints = {
  getMaintenance
}

export default endpoints

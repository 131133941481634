import dayjs from 'dayjs'
import { saveAs } from 'file-saver'
import { PER_PAGE } from '@/data'
import { matchfyClient } from '@/lib'

export const listRadios = async (params) => {
  const page = params?.pageParam || 0
  const limit = parseInt(params?.limit || PER_PAGE)
  const offset = parseInt(page * limit)
  const response = await matchfyClient.get(`/radio`, { params: { limit, offset } })
  return response.data
}

export const exportRadio = async (ids) => {
  const response = await matchfyClient.post(`/radio/export`, {
    selectedIds: ids,
    responseType: 'blob',
  })
  const blob = new Blob([response.data], { type: "text/csv;charset=utf-8" })
  saveAs(blob, `radio_export_${dayjs().format('YYYY-MM-DD')}.csv`)
}

const endpoints = {
  exportRadio,
  listRadios
}

export default endpoints

import { matchfyClient } from '@/lib'

const logout = async () => {
  const response = await matchfyClient.post('/auth/logout')
  return response.data
}

const authorize = async (body) => {
  const response = await matchfyClient.post('/auth/login', body)
  return response.data
}

const refreshTokenSpotify = async (token) => {
  const response = await matchfyClient.post('/auth/refresh-spotify', {}, {
    headers: {
      'Authorization': `Bearer ${token}`,
    }
  })
  return response.data
}

export const authorizeDistribution = async () => {
  const response = await matchfyClient.post('/auth/authorize-distribution')
  return response.data
}

const verifyUserEmail = async (verifyToken) => {
  const response = await matchfyClient.post('/auth/email/verify', { token: verifyToken })
  return response.data
}

const sendVerificationEmail = async () => {
  const response = await matchfyClient.post('/auth/email/send-verify')
  return response.data
}

const endpoints = {
  authorize,
  refreshTokenSpotify,
  authorizeDistribution,
  logout,
  sendVerificationEmail,
  verifyUserEmail
}

export default endpoints

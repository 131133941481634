import { matchfyClient } from '@/lib'

export const getInvoice = async (invoiceToken) => {
  const response = await matchfyClient.get(`/open/invoices/${invoiceToken}`)
  return response.data
}

const endpoints = {
  getInvoice,
}

export default endpoints

import axios from 'axios'
import { throttle } from 'lodash-es'
import { getServerSession } from 'next-auth/next'
import { getSession } from 'next-auth/react'
import { trackTimeOnline } from '@/api/matchfy/auditLogs'
import { createAnalytics } from '@/api/matchfy/dashboard'
import {
  TRACK_USER_ACTIVITY_THROTTLE,
  TRACK_USER_DASHBOARD_ANALYTICS_THROTTLE
} from '@/data/config'
import { authConfig } from "@/lib/auth"
import { isServer } from '@/utils'

export const URLS_WITHOUT_TRACKING_ENABLED = [
  'api/auth',
  'audit-logs/time-online',
  'chat/count',
  'dashboard/analytics',
  'open',
  'notifications/digest',
  'users/me',
]

const development = process.env.NODE_ENV === 'development'

const getBackendApiUrl = () => {
  if (development && isServer) {
    return process.env.NEXT_PUBLIC_API_SERVER_URL
  }

  return process.env.NEXT_PUBLIC_API_URL
}

const matchfyClient = axios.create({
  baseURL: getBackendApiUrl(),
  headers: {
    'Content-Type': 'application/json',
    'X-Type': 'web',
  },
})

let sessionPromise = null

const getSessionPromise = async () => {
  if (!sessionPromise) {
    sessionPromise = isServer ? getServerSession(authConfig) : getSession()
  }

  return sessionPromise
}

const trackOnlineTime = throttle(
  trackTimeOnline,
  TRACK_USER_ACTIVITY_THROTTLE
)

const trackDashboardAnalytics = throttle(
  createAnalytics,
  TRACK_USER_DASHBOARD_ANALYTICS_THROTTLE
)

matchfyClient.interceptors.request.use(async (config) => {
  if (config.headers.Authorization) {
    return config
  }

  const session = await getSessionPromise()

  if (session?.token?.userToken) {
    config.headers.Authorization = `Bearer ${session.token.userToken}`
  }

  const canTrackActivity = !URLS_WITHOUT_TRACKING_ENABLED.some((url) => {
    const configUrl = config.url?.toLowerCase() || ''
    const checkUrl = url.toLowerCase()
    return configUrl === checkUrl || configUrl.startsWith(checkUrl + '/')
  })

  if (session && canTrackActivity && !isServer) {
    trackOnlineTime()
    trackDashboardAnalytics()
  }

  return config
})

const spotifyClient = axios.create({
  baseURL: process.env.NEXT_PUBLIC_SPOTIFY_API_URL || 'https://api.spotify.com/v1',
  headers: {
    'Content-Type': 'application/json',
  }
})

spotifyClient.interceptors.request.use(async (config) => {
  const session = await getSessionPromise()

  if (session?.token?.spotifyToken) {
    config.headers.Authorization = `Bearer ${session.token.spotifyToken}`
  }
  return config
})

export {
  matchfyClient,
  spotifyClient
}

export default matchfyClient

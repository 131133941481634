import { matchfyClient } from '@/lib'

export const getTokenByCode = async (code) => {
  const response = await matchfyClient.get(`/token/${code}`)
  return response.data
}

const endpoints = {
  getTokenByCode,
}

export default endpoints

import { default as accelerateEndpoints } from './accelerate'
import { default as affiliateEndpoints } from './affiliate'
import { default as analysisEndpoints } from './analysis'
import { default as artistsEndpoints } from './artists'
import { default as auditLogsEndpoints } from './auditLogs'
import { default as authEndpoints } from './auth'
import { default as blockEndpoints } from './block'
import { default as cartEndpoints } from './cart'
import { default as chatEndpoints } from './chat'
import { default as consentEndpoints } from './consent'
import { default as curatorEndpoints } from './curator'
import { default as dashboardEndpoints } from './dashboard'
import { default as devEndpoints } from './dev'
import { default as discountEndpoints } from './discount'
import { default as downloadEndpoints } from './downloads'
import { default as genresEnrpoints } from './genres'
import { default as invoiceEndpoints } from './invoice'
import { default as magazineEndpoints } from './magazine'
import { default as maintenanceEndpoints } from './maintenance'
import { default as matchEndpoints } from './match'
import { default as mediaEndpoints } from './media'
import { default as musicMindEndpoints } from './musicMind'
import { default as notificationsEndpoints } from './notification'
import { default as openEndpoints } from './open'
import { default as plansEndpoints } from './plans'
import { default as playlistEndpoints } from './playlist'
import { default as playlistWatchEndpoints } from './playlistCheck'
import { default as pushEndpoints } from './push'
import { default as radioEndpoints } from './radio'
import { default as rankEndpoints } from './rank'
import { default as requestEndpoints } from './request'
import { default as roleEndpoints } from './role'
import { default as searchEndpoints } from './search'
import { default as tiktokEndpoints } from './tiktok'
import { default as tokenEndpoints } from './token'
import { default as tracksPlayEndpoints } from './tracksPlay'
import { default as transactionEndpoints } from './transaction'
import { default as userEndpoints } from './user'
import { default as vipCampaignEndpoints } from './vipCampaign'
import { default as wantedEndpoints } from './wanted'

const endpoints = {
  ...accelerateEndpoints,
  ...affiliateEndpoints,
  ...analysisEndpoints,
  ...artistsEndpoints,
  ...auditLogsEndpoints,
  ...authEndpoints,
  ...blockEndpoints,
  ...consentEndpoints,
  ...chatEndpoints,
  ...cartEndpoints,
  ...curatorEndpoints,
  ...dashboardEndpoints,
  ...discountEndpoints,
  ...downloadEndpoints,
  ...devEndpoints,
  ...genresEnrpoints,
  ...invoiceEndpoints,
  ...matchEndpoints,
  ...magazineEndpoints,
  ...maintenanceEndpoints,
  ...matchEndpoints,
  ...mediaEndpoints,
  ...musicMindEndpoints,
  ...notificationsEndpoints,
  ...openEndpoints,
  ...plansEndpoints,
  ...playlistEndpoints,
  ...playlistWatchEndpoints,
  ...pushEndpoints,
  ...radioEndpoints,
  ...rankEndpoints,
  ...requestEndpoints,
  ...roleEndpoints,
  ...searchEndpoints,
  ...tiktokEndpoints,
  ...tokenEndpoints,
  ...tracksPlayEndpoints,
  ...transactionEndpoints,
  ...userEndpoints,
  ...vipCampaignEndpoints,
  ...wantedEndpoints,
}

export default endpoints

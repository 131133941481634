import { commonVariables, themes } from './themeConfig'
import { hexToRGB, hexToHSL } from '@/utils'

export const dashed = (str) => {
  return str.replace(/[A-Z]/g, (m) => `-${m.toLowerCase()}`)
}

// Modify setThemeVariables function
export const setThemeVariables = (theme) => {
  const themeClasses = themes.map((t) => t.id)
  document.body.classList.remove(...themeClasses)
  document.body.classList.add(theme.id)
  const root = document.querySelector(':root')

  // Set theme palette
  Object.keys(theme.palette).forEach((color) => {
    root.style.setProperty(`--${dashed(color)}-color`, theme.palette[color])
    root.style.setProperty(
      `--${dashed(color)}-color-rgb`,
      hexToRGB(theme.palette[color])
    )
  })

  // Set shadow properties
  root.style.setProperty(
    '--shadow-color',
    `hsl(${hexToHSL(theme.shadow.color)}, ${theme.shadow.opacity})`
  )
  root.style.setProperty(
    '--shadow-color-rgb',
    `${hexToRGB(theme.shadow.color)}`
  )

  // Set common variables
  Object.entries(commonVariables).forEach(([category, values]) => {
    if (typeof values === 'object') {
      Object.entries(values).forEach(([name, value]) => {
        const varName = `--${dashed(category)}${name === 'base' ? '' : `-${dashed(name)}`}`
        root.style.setProperty(varName, value)
      })
    }
  })
}

import { matchfyClient } from '@/lib'

export const getDownload = async (id) => {
  const response = await matchfyClient.get(`downloads/${id}`)
  return response.data
}

export const getDownloadFile = async (id) => {
  const response = await matchfyClient.get(`downloads/${id}/file`)
  return response.data
}

export const listDownloads = async (locale) => {
  const response = await matchfyClient.get(`downloads?locale=${locale}`)
  return response.data
}

const endpoints = {
  getDownload,
  getDownloadFile,
  listDownloads
}

export default endpoints

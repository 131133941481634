import { matchfyClient } from '@/lib'

export const createAnalytics = async () => {
  const response = await matchfyClient.post('/dashboard/analytics')
  return response.data
}

export const createQueueAnalytics = async () => {
  const response = await matchfyClient.patch('/dashboard/analytics')
  return response.data
}

export const getArtistDashboard = async () => {
  const response = await matchfyClient.get(`/dashboard/artist`)
  return response.data
}

export const getCuratorDashboard = async () => {
  const response = await matchfyClient.get(`/dashboard/curator`)
  return response.data
}

const endpoints = {
  createAnalytics,
  createQueueAnalytics,
  getArtistDashboard,
  getCuratorDashboard
}

export default endpoints
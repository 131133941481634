import { matchfyClient } from '@/lib'

export const getPlaylistFakeScore = async (playlistId) => {
  const response = await matchfyClient.get(`music-mind/${playlistId}/fake-score`)
  return response.data
}

export const getPlaylistGenres = async (playlistId) => {
  const response = await matchfyClient.get(`music-mind/${playlistId}/genres`)
  return response.data
}
export const getPlaylistGenresUpdatedAt = async (playlistId) => {
  const response = await matchfyClient.get(`music-mind/${playlistId}/genres-updated`)
  return response.data
}

export const requestPlaylistGenresUpdate = async (playlistId) => {
  const response = await matchfyClient.post(`music-mind/${playlistId}/genres-update`)
  return response.data
}

export const updatePlaylistGenres = async (playlistId, genres) => {
  const response = await matchfyClient.put(`music-mind/${playlistId}/genres-update`, {
    genres,
  })
  return response.data
}

const endpoints = {
  getPlaylistFakeScore,
  getPlaylistGenres,
  getPlaylistGenresUpdatedAt,
  requestPlaylistGenresUpdate,
  updatePlaylistGenres
}

export default endpoints

import { matchfyClient } from '@/lib'

export const hasAccelerate = async () => {
  const response = await matchfyClient.get('/accelerate/has-accelerate')
  return response.data
}

export const createAccelerate = async (payload) => {
  const response = await matchfyClient.post('/accelerate', { ...payload })
  return response.data
}

export const listAccelerates = async () => {
  const response = await matchfyClient.get('/accelerate')
  return response.data
}

const endpoints = {
  createAccelerate,
  listAccelerates,
  hasAccelerate
}

export default endpoints

import { matchfyClient } from '@/lib'

export const getAnalysisCount = async () => {
  const response = await matchfyClient.get('/analysis/count')
  return response.data
}

export const getArtistAnalysis = async (artist) => {
  const response = await matchfyClient.post(`/analysis/${artist.id}`, {
    artist,
    responseType: 'blob',
  })
  return response.data
}

const endpoints = {
  getArtistAnalysis,
  getAnalysisCount
}

export default endpoints

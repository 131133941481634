import { matchfyClient } from '@/lib'

export const getBanner = async () => {
  const response = await matchfyClient.get('/open/banner')
  return response.data
}

export const getPlans = async (lang) => {
  const response = await matchfyClient.get(`/open/plans?lang=${lang}`)
  return response.data
}

export const getProducts = async (lang) => {
  const response = await matchfyClient.get(`/open/plans/products?lang=${lang}`)
  return response.data
}

export const getFeaturedCurators = async () => {
  const response = await matchfyClient.get('/open/curators/featured')
  return response.data
}

export const getRandomCurators = async () => {
  const response = await matchfyClient.get('/open/curators/random')
  return response.data
}

export const getStarCurators = async () => {
  const response = await matchfyClient.get('/open/curators/star')
  return response.data
}

export const getPopularSubmissions = async (query) => {
  const response = await matchfyClient.get(`/open/submissions/popular?${query}`)
  return response.data
}

export const getFeaturedPromos = async () => {
  const response = await matchfyClient.get('/open/promo')
  return response.data
}

export const subscribeUser = async (token) => {
  const response = await matchfyClient.get(`/open/subscribe?token=${token}`)
  return response.data
}

export const unsubscribeUser = async (token) => {
  const response = await matchfyClient.get(`/open/unsubscribe?token=${token}`)
  return response.data
}

const endpoints = {
  getBanner,
  getFeaturedCurators,
  getFeaturedPromos,
  getPlans,
  getPopularSubmissions,
  getProducts,
  getRandomCurators,
  getStarCurators,
  subscribeUser,
  unsubscribeUser
}

export default endpoints

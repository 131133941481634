import { matchfyClient } from '@/lib'

export const getAffiliateOrReferral = async (code) => {
  const response = await matchfyClient.get(`/affiliates/${code}`)
  return response.data
}

export const useAffiliateCode = async (code) => {
  const response = await matchfyClient.post(`/affiliates/${code}`)
  return response.data
}

const endpoints = {
  getAffiliateOrReferral,
  useAffiliateCode
}

export default endpoints
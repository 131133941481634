import { PER_PAGE } from '@/data'
import { matchfyClient } from '@/lib'

export const isTikTokUserInQueue = async (tikTokId, trackId) => {
  const response = await matchfyClient.get(`/tiktok/${tikTokId}/queue`, { params: { trackId } })
  return response.data
}

export const addTikTokToQueue = async (tikTokId, trackId) => {
  const response = await matchfyClient.post(`/tiktok/${tikTokId}/queue`, { trackId })
  return response.data
}

export const listTikToks = async (params) => {
  const page = params?.pageParam || 1
  const limit = parseInt(params?.limit || PER_PAGE)
  const offset = parseInt((page - 1) * limit)
  const { genres, trackId, artistIds } = params

  const response = await matchfyClient.post(`/tiktok`, {
    limit,
    offset,
    genres: genres,
    trackId,
    artistIds: artistIds
  })

  return response.data
}

const endpoints = {
  addTikTokToQueue,
  listTikToks,
  isTikTokUserInQueue
}

export default endpoints

import dayjs from 'dayjs'
import { saveAs } from 'file-saver'
import { matchfyClient } from '@/lib'

const completeProfile = async () => {
  const response = await matchfyClient.put(`/users/complete-profile`)
  return response.data
}

const deleteUser = async () => {
  const response = await matchfyClient.delete('/users/delete')
  return response.data
}

const exportUserData = async () => {
  const response = await matchfyClient.get('/users/export', {
    responseType: 'blob',
  })

  const blob = new Blob([response.data], { type: "text/csv;charset=utf-8" })
  saveAs(blob, `user_export_${dayjs().format('YYYY-MM-DD')}.csv`)
}

const getMe = async (token) => {
  const response = await matchfyClient.get('/users/me', {
    headers: {
      'Authorization': `Bearer ${token}`,
    }
  })
  return response.data
}

const getUserProfile = async (userId) => {
  const response = await matchfyClient.get(`/users/${userId}`)
  return response.data
}

const saveBillingInfo = async (billingInfo) => {
  const response = await matchfyClient.put('/users/billing', billingInfo)
  return response.data
}

const updateUserContacts = async (contacts) => {
  const response = await matchfyClient.put(`/users/contacts`, { contacts })
  return response.data
}

const updateUserSettings = async (key, value) => {
  const response = await matchfyClient.put(`/users/settings/${key}`, { value })
  return response.data
}

const endpoints = {
  completeProfile,
  deleteUser,
  exportUserData,
  getMe,
  getUserProfile,
  saveBillingInfo,
  updateUserContacts,
  updateUserSettings,
}

export default endpoints

import { matchfyClient } from '@/lib'

export const getRank = async () => {
  const response = await matchfyClient.get(`/rank`)
  return response.data
}

const endpoints = {
  getRank,
}

export default endpoints
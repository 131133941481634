import { PER_PAGE } from '@/data'
import { matchfyClient } from '@/lib'

export const autoAcceptEnable = async (playlistId, minMatchRate) => {
  const response = await matchfyClient.put(`/playlists/${playlistId}/auto-submit/enable`, {
    minMatchRate,
  })
  return response.data
}

export const autoAcceptDisable = async (playlistId) => {
  const response = await matchfyClient.put(`/playlists/${playlistId}/auto-submit/disable`)
  return response.data
}

export const getPlaylist = async (playlistId) => {
  const response = await matchfyClient.get(`/playlists/${playlistId}`)
  return response.data
}

export const getPlaylistImage = async (playlistId) => {
  const response = await matchfyClient.put(`/playlists/${playlistId}/images`, {})
  return response.data
}

export const fetchPlaylists = async () => {
  const response = await matchfyClient.put('/playlists/fetch')
  return response.data
}

export const getUserPlaylists = async (params) => {
  const page = params?.pageParam || 1
  const limit = parseInt(params?.limit || PER_PAGE)
  const offset = parseInt((page - 1) * limit)

  const response = await matchfyClient.get(`/playlists/`, {
    params: { limit, offset },
  })
  return response.data
}

export const togglePlaylistEnabled = async (playlistId, isVerified) => {
  const response = await matchfyClient.put(`/playlists/${playlistId}/enable`, {
    isVerified,
  })
  return response.data
}

const endpoints = {
  autoAcceptDisable,
  autoAcceptEnable,
  fetchPlaylists,
  getPlaylist,
  getPlaylistImage,
  getUserPlaylists,
  togglePlaylistEnabled,
}

export default endpoints

import { matchfyClient } from '@/lib'

export const getConsentDocuments = async () => {
  const response = await matchfyClient.get(`/consent/documents`)
  return response.data
}

const endpoints = {
  getConsentDocuments,
}

export default endpoints

import { matchfyClient } from '@/lib'

export const getNotificationDigest = async () => {
  const response = await matchfyClient.get('/notifications/digest')
  return response.data
}

export const getNotificationsByStatus = async (status) => {
  const response = await matchfyClient.get(`/notifications/${status}`)
  return response.data
}

export const listNotifications = async () => {
  const response = await matchfyClient.get('/notifications')
  return response.data
}

export const markNotificationsAsRead = async (notificationsIds) => {
  const response = await matchfyClient.post('/notifications/read', { ids: notificationsIds })
  return response.data
}

const endpoints = {
  getNotificationDigest,
  getNotificationsByStatus,
  listNotifications,
  markNotificationsAsRead
}

export default endpoints

import { matchfyClient } from '@/lib'

export const listRoles = async () => {
  const response = await matchfyClient.get(`/roles/`)
  return response.data
}

const endpoints = {
  listRoles,
}

export default endpoints

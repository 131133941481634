import { matchfyClient } from '@/lib'

export const createVipCampaign = async (payload) => {
  const response = await matchfyClient.post(`/vip`, { payload })
  return response.data
}

export const deleteVipCampaign = async () => {
  const response = await matchfyClient.delete(`/vip`)
  return response.data
}

export const getVipCampaignById = async (id) => {
  const response = await matchfyClient.get(`/vip/${id}`)
  return response.data
}

export const listVipCampaigns = async () => {
  const response = await matchfyClient.get(`/vip`)
  return response.data
}

export const requestVipCampaign = async (payload) => {
  const response = await matchfyClient.post(`/vip/request`, { payload })
  return response.data
}

const endpoints = {
  createVipCampaign,
  deleteVipCampaign,
  getVipCampaignById,
  listVipCampaigns,
  requestVipCampaign
}

export default endpoints
import { PER_PAGE } from '@/data'
import { matchfyClient } from '@/lib'

export const listTracksPlay = async (params) => {
  const page = params?.pageParam || 1
  const limit = parseInt(params?.limit || PER_PAGE)
  const offset = parseInt((page - 1) * limit)
  const response = await matchfyClient.get(`/tracks-play?limit=${limit}&offset=${offset}`)
  return response.data
}

export const playTrack = async (trackId) => {
  const response = await matchfyClient.post(`/tracks-play/${trackId}/play`)
  return response.data
}

const endpoints = {
  listTracksPlay,
  playTrack
}

export default endpoints

import dayjs from "dayjs"
import { matchfyApi } from '@/api'
import { ROUTES, SPOTIFY_SCOPES } from "@/data"

const Matchfy = (options) => ({
  id: "matchfy",
  name: "Matchfy",
  type: "oauth",
  authorization: "https://accounts.spotify.com/authorize?scope=user-read-email",
  token: "https://accounts.spotify.com/api/token",
  userinfo: "https://api.spotify.com/v1/me",
  options,
})

export const authConfig = {
  providers: [
    Matchfy({
      authorization: `https://accounts.spotify.com/authorize?scope=${SPOTIFY_SCOPES.join(' ')}`,
      token: "https://accounts.spotify.com/api/token",
      userinfo: "https://api.spotify.com/v1/me",
      clientId: process.env.SPOTIFY_CLIENT_ID,
      clientSecret: process.env.SPOTIFY_CLIENT_SECRET,
      profile(profile) {
        return profile
      },
    }),
  ],
  callbacks: {
    async jwt({ token, account, profile }) {
      if (account && profile) {
        const authorizationToken = await matchfyApi.authorize({
          accessToken: account.access_token,
          refreshToken: account.refresh_token,
          expiresAt: account.expires_at,
          preferredLanguage: 'en',
          type: 'web',
        })

        return {
          ...authorizationToken,
          spotify: {
            id: account.providerAccountId,
            token: account.access_token,
            refreshToken: account.refresh_token,
            expiresAt: account.expires_at,
          }
        }
      }

      if (dayjs.unix(token.spotify.expiresAt).isBefore(dayjs())) {
        try {
          const refreshToken = await matchfyApi.refreshTokenSpotify(token.userToken)
          return {
            ...token,
            spotify: {
              ...token.spotify,
              token: refreshToken.token,
              expiresAt: refreshToken.expiresAt,
            }
          }
        } catch (error) {
          console.error('Error refreshing token', error)
          return null
        }
      }
      return token
    },

    async session({ token }) {
      if (!token) {
        throw new Error('Invalid token')
      }

      const { userToken, expiresAt, newUser } = token
      if (!userToken || !expiresAt || dayjs(expiresAt).isBefore(dayjs())) {
        throw new Error('Invalid token')
      }

      try {
        const me = await matchfyApi.getMe(userToken)

        return {
          ...me,
          newUser,
          token: {
            userToken,
            spotifyToken: token.spotify.token,
          }
        }
      } catch (error) {
        console.error('Error fetching user', error)
        throw new Error('Error fetching user')
      }
    },
  },
  pages: {
    signIn: ROUTES.LOGIN,
  }
}

export default authConfig
import hotToast from 'react-hot-toast'

export const toast = (type, message, icon) => {
  switch (type) {
    case 'success':
      hotToast.success(message)
      break
    case 'error':
      hotToast.error(message)
      break
    case 'icon':
      hotToast(message, {
        icon: icon,
      })
      break
    default:
      throw new Error('Invalid toast type')
  }
}

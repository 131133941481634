import { matchfyClient } from '@/lib'

export const createCart = async (payload) => {
  const response = await matchfyClient.post(`/cart`, payload)
  return response.data
}

const endpoints = {
  createCart,
}

export default endpoints
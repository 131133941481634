import { matchfyClient } from '@/lib'

export const canUseDiscount = async (code, query) => {
  const response = await matchfyClient.get(`/discount/can-use/${code}?${query}`)
  return response.data
}

export const getDiscountByCode = async (code) => {
  const response = await matchfyClient.get(`/discount/${code}`)
  return response.data
}

export const useDiscount = async (code, plan, price) => {
  const response = await matchfyClient.post(`/discount/${code}`, { plan, price })
  return response.data
}

const endpoints = {
  canUseDiscount,
  getDiscountByCode,
  useDiscount
}

export default endpoints

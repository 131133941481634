import { matchfyClient } from '@/lib'

export const getWatchedPlaylist = async (playlistId) => {
  const response = await matchfyClient.get(`/playlist-check/${playlistId}`)
  return response.data
}

export const getWatchedPlaylistCharts = async (
  playlistId,
  startDate,
  endDate,
  latest = false
) => {
  if (latest) {
    const response = await matchfyClient.get(`/playlist-check/${playlistId}/charts?latest=true`)
    return response.data
  }
  const response = await matchfyClient.get(
    `/playlist-check/${playlistId}/charts?startDate=${startDate}&endDate=${endDate}`
  )
  return response.data
}

export const listWatchedPlaylists = async () => {
  const response = await matchfyClient.get('/playlist-check/watching')
  return response.data
}

export const unwatchPlaylist = async (playlistId) => {
  const response = await matchfyClient.delete(`/playlist-check/${playlistId}/unwatch`)
  return response.data
}

export const watchPlaylist = async (playlistId) => {
  const response = await matchfyClient.post(`/playlist-check/${playlistId}/watch`)
  return response.data
}

const endpoints = {
  getWatchedPlaylist,
  getWatchedPlaylistCharts,
  listWatchedPlaylists,
  unwatchPlaylist,
  watchPlaylist
}

export default endpoints

import { PER_PAGE } from '@/data'
import { matchfyClient } from '@/lib'

export const archiveChat = async (chatId) => {
  const response = await matchfyClient.put(`/chat/${chatId}/archive`)
  return response.data
}

export const createChat = async (participants) => {
  const response = await matchfyClient.post('/chat', { participants })
  return response.data
}

export const createToken = async (identity) => {
  const response = await matchfyClient.post('/chat/token', { identity })
  return response.data
}

export const deleteChat = async (chatId) => {
  const response = await matchfyClient.delete(`/chat/${chatId}`)
  return response.data
}

export const getChatNotificationCount = async () => {
  const response = await matchfyClient.get('/chat/count')
  return response.data
}

export const joinChat = async (chatId, participants) => {
  const response = await matchfyClient.post('/chat/join', { chatId, participants })
  return response.data
}

export const listChats = async (params, identity) => {
  const page = params?.pageParam || 1
  const limit = parseInt(params?.limit || PER_PAGE)
  const offset = parseInt((page - 1) * limit)

  const response = await matchfyClient.get('/chat', { params: { identity, limit, offset } })
  return response.data
}

export const syncChats = async (chats) => {
  const response = await matchfyClient.put('/chat/sync', { chats })
  return response.data
}

export const unarchiveChat = async (chatId) => {
  const response = await matchfyClient.put(`/chat/${chatId}/unarchive`)
  return response.data
}

const endpoints = {
  archiveChat,
  createChat,
  createToken,
  deleteChat,
  getChatNotificationCount,
  joinChat,
  listChats,
  syncChats,
  unarchiveChat
}

export default endpoints

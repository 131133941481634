import { matchfyClient } from '@/lib'

export const calculateVipPrice = async (artistPopularity, trackPopularity, genres, code) => {
  const response = await matchfyClient.get(`plans/vip/price`, {
    params: { artistPopularity, trackPopularity, genres, code },
  })
  return response.data
}

export const getAnalysisPrice = async () => {
  const response = await matchfyClient.get(`plans/analysis/price`)
  return response.data
}

export const getExportPlaylistPrice = async () => {
  const response = await matchfyClient.get(`plans/playlist/price`)
  return response.data
}

export const getProfileFeaturedPrice = async () => {
  const response = await matchfyClient.get(`plans/profile-featured/price`)
  return response.data
}

export const getPlaylistFeaturedPrice = async () => {
  const response = await matchfyClient.get(`plans/playlist-featured/price`)
  return response.data
}

const endpoints = {
  calculateVipPrice,
  getAnalysisPrice,
  getExportPlaylistPrice,
  getProfileFeaturedPrice,
  getPlaylistFeaturedPrice,
}

export default endpoints

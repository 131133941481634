import { PER_PAGE } from '@/data'
import { matchfyClient } from '@/lib'

export const getMatchScore = async (playlistId, trackId, trackFeatures) => {
  const response = await matchfyClient.post(`/match/${playlistId}/${trackId}/score`, {
    trackFeatures,
  })
  return response.data
}

export const listMatchPlaylists = async (params) => {
  const page = params?.pageParam || 1
  const limit = parseInt(params?.limit || PER_PAGE)
  const offset = parseInt((page - 1) * limit)
  const { genres, trackId, artistIds, trackFeatures } = params

  const response = await matchfyClient.post(`/match`, {
    limit,
    offset,
    genres: genres,
    trackId,
    artistIds: artistIds,
    trackFeatures,
  })

  return response.data
}

export const pushToPlaylist = async ({ playlistId, track, trackId, checksum }) => {
  const response = await matchfyClient.post(`/match/${playlistId}/push`, {
    trackId,
    track,
    checksum,
  })
  return response.data
}

const endpoints = {
  getMatchScore,
  listMatchPlaylists,
  pushToPlaylist
}

export default endpoints

import { matchfyClient } from '@/lib'

export const trackTimeOnline = async () => {
  const response = await matchfyClient.post('/audit-logs/time-online')
  return response.data
}

const endpoints = {
  trackTimeOnline
}

export default endpoints
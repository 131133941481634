export const commonVariables = {
  navbar: {
    height: '4rem',
    heightMobile: '64px',
  },
  radius: {
    xs: '0.25rem',
    sm: '0.5rem',
    base: '0.8rem',
    lg: '1.2rem',
    full: '9999px',
  },
  gutter: {
    xs: '0.25rem',
    sm: '0.5rem',
    base: '1rem',
    md: '1.5rem',
    lg: '2rem',
    xl: '3rem',
  },
  input: {
    height: '46px',
  },
  fontFamily: {
    default: '"Inter", system-ui, BlinkMacSystemFont, -apple-system, Segoe UI, Helvetica Neue, sans-serif',
    heading: '"Basier Circle", system-ui, BlinkMacSystemFont, -apple-system, Segoe UI, Helvetica Neue, sans-serif',
    mono: '"Inconsolata", monospace',
  },
  curve: {
    fast: 'cubic-bezier(0.694, 0.048, 0.335, 1)',
    out: 'cubic-bezier(0.165, 0.84, 0.44, 1)',
    slow: 'cubic-bezier(0.07, 0.51, 0.12, 1)',
  },
  time: {
    faster: '.15s',
    fast: '0.25s',
    base: '0.35s',
    slow: '0.5s',
  },
}

export const themes = [
  {
    id: 'dark',
    name: 'Dark',
    enable: true,
    palette: {
      primary: '#7866ff',
      themePrimary: '#7866ff',
      primaryLight: '#26223f',
      secondary: '#225CF4',
      themeSecondary: '#225CF4',
      secondaryLight: '#1d233d',
      tertiary: '#2196f3',
      tertiaryLight: '#1d233d',
      primaryBg: '#18191c',
      secondaryBg: '#1f2023',
      primaryText: '#fafafa',
      secondaryText: '#ededed',
      blue: '#2196f3',
      gold: '#cfae54',
      silver: '#8f8f8f',
      bronze: '#cd7f32',
      green: '#1DB854',
      darkGreen: '#127636',
      greenLight: '#17231e',
      red: '#f44336',
      redLight: '#2b191c',
      orange: '#fb923c',
      yellow: '#eab308',
      accent: '#a094fb',
      border: '#2d2d2d',
      input: '#25272b',
      neutral: '#505050',
      overlay: '#131317',
      invert: '#ffffff',
      invertLight: '#1f1f23',
      dark: '#131317',
      black: '#000000',
      white: '#ffffff',
      spotifyPrimary: '#1db954',
      tiktokPrimary: '#ff0050',
      tiktokSecondary: '#00f2ea',
      youtubePrimary: '#ff0000',
      wantedPrimary: '#d35009',
    },
    shadow: {
      color: '#000000',
      opacity: 0.5,
    },
  },
  {
    id: 'light',
    name: 'Light',
    enable: true,
    palette: {
      primary: '#5e4af9',
      themePrimary: '#5e4af9',
      primaryLight: '#efedfe',
      secondary: '#225CF4',
      themeSecondary: '#225CF4',
      secondaryLight: '#ebf0fe',
      tertiary: '#2196f3',
      tertiaryLight: '#ebf0fe',
      primaryBg: '#ffffff',
      secondaryBg: '#fafafa',
      primaryText: '#232323',
      secondaryText: '#383A3C',
      blue: '#2196f3',
      gold: '#cfae54',
      silver: '#8f8f8f',
      bronze: '#cd7f32',
      green: '#1DB854',
      darkGreen: '#127636',
      greenLight: '#ecf7ed',
      red: '#f44336',
      redLight: '#ffedec',
      orange: '#fb923c',
      yellow: '#fbc81b',
      accent: '#a094fb',
      border: '#d8dee5',
      input: '#f7f8fa',
      neutral: '#d4d4d4',
      overlay: '#131317',
      invert: '#131317',
      invertLight: '#f4f4f4',
      dark: '#131317',
      black: '#000000',
      white: '#ffffff',
      spotifyPrimary: '#1db954',
      tiktokPrimary: '#ff0050',
      tiktokSecondary: '#00f2ea',
      youtubePrimary: '#ff0000',
      wantedPrimary: '#d35009',
    },
    shadow: {
      color: '#000000',
      opacity: 0.1,
    },
  },
]

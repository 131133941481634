import { chunk, isEmpty } from 'lodash-es'
import { spotifyClient } from '@/lib'

export const getAllEntities = async (url, limit = 50, offset = 0, items = []) => {
  const response = await spotifyClient.get(url, {
    params: {
      limit,
      offset,
    },
  })

  if (!response.data) {
    throw new Error('Something went wrong, can`t get playlist`s tracks!')
  }

  const data = response.data
  const totalItemsFetched = [...items, ...data.items]

  if (data.next) {
    return getAllEntities(url, limit, offset + limit, totalItemsFetched)
  }

  data.items = totalItemsFetched
  return data
}

export const getPlaylistTracks = async (playlistId, limit = 100, offset = 0) => {
  return getAllEntities(`/playlists/${playlistId}/tracks`, limit, offset)
}

export const getPlaylistTracksByPage = async (playlistId, limit = 100, offset = 0) => {
  const response = await spotifyClient.get(`/playlists/${playlistId}/tracks`, {
    params: {
      limit,
      offset,
    },
  })
  return response?.data?.items
}

export const searchItem = async (query, type) => {
  const response = await spotifyClient.get('/search', {
    params: {
      q: query,
      type: type,
      limit: 40,
    },
  })
  return response.data
}

export const getPlaylist = async (playlistId) => {
  const response = await spotifyClient.get(`playlists/${playlistId}`)
  return response.data
}

export const getPlaylistById = async (playlistId) => {
  const response = await spotifyClient.get(`playlists/${playlistId}`)
  return response.data
}

export const followPlaylist = async (playlistId) => {
  const response = await spotifyClient.put(`playlists/${playlistId}/followers`)
  return response.data
}

export const followArtists = async (artistIds) => {
  const response = await spotifyClient.put('/me/following',
    { ids: artistIds },
    { params: { type: 'artist' } }
  )
  return response.data
}

export const unfollowArtists = async (artistIds) => {
  const response = await spotifyClient.delete('/me/following',
    { ids: artistIds },
    { params: { type: 'artist' } }
  )
  return response.data
}

export const getPlaylistWithTracks = async (playlistId) => {
  const promises = []
  promises.push(getPlaylistTracks(playlistId))
  promises.push(getPlaylist(playlistId))
  return from(
    Promise.all(promises)
      .then((res) => {
        res[1].tracks.items = res[0].items
        return res[1]
      })
      .catch(async (error) => {
        console.log(error)
      })
  )
}

export const getAlbumById = async (albumId) => {
  const response = await spotifyClient.get(`/albums/${albumId}`)
  return response.data
}

export const getArtist = async (artistId) => {
  const response = await spotifyClient.get(`/artists/${artistId}`)
  return response.data
}

export const getArtists = async (artistIds) => {
  const response = await spotifyClient.get('/artists', { params: { ids: artistIds.join(',') } })
  return response.data
}

export const getArtistById = async (artistId) => {
  const response = await spotifyClient.get(`/artists/${artistId}`)
  return response.data
}

export const getTrack = async (trackId) => {
  const response = await spotifyClient.get(`/tracks/${trackId}`)
  return response.data
}

export const getTrackById = async (trackId) => {
  const response = await spotifyClient.get(`/tracks/${trackId}`)
  return response.data
}

export const getTrackFeatures = async (trackId) => {
  const response = await spotifyClient.get(`/audio-features/${trackId}`)
  return response.data
}

export const getShowById = async (showId) => {
  const response = await spotifyClient.get(`/shows/${showId}`)
  return response.data
}

export const getUserProfile = async (userId) => {
  const response = await spotifyClient.get(`/users/${userId}`)
  return response.data
}

export const getUserPlaylists = async (userId) => {
  const response = await spotifyClient.get(`/users/${userId}/playlists`)
  return response.data
}

export const getSeveralArtists = async (artistIds) => {
  if (!Array.isArray(artistIds) || !artistIds) {
    throw new Error('Something went wrong, can`t get several artists!')
  }

  const response = await spotifyClient.get('/artists', { params: { ids: artistIds.join(',') } })
  return response.data
}

export async function getArtistsBulk(artistIds) {
  if (!artistIds || !Array.isArray(artistIds))
    throw new Error('Something went wrong, can`t get several artists!')
  if (isEmpty(artistIds)) return { artists: [] }

  if (artistIds.length <= 50) return await getSeveralArtists(artistIds)

  const promises = []
  const chunksArtistIds = chunk(artistIds, 50)
  chunksArtistIds.forEach((chunkArtistIds) =>
    promises.push(getSeveralArtists(chunkArtistIds))
  )

  const chunksArtists = await Promise.all(promises)

  return chunksArtists.reduce((acc, { artists }) => {
    return { artists: [...artists, ...acc.artists] }
  })
}

export const removeTracksFromPlaylist = async (playlistId, tracksId) => {
  const urn = `/playlists/${playlistId}/tracks`

  const tracks = tracksId.map((trackId) => ({
    uri: `spotify:track:${trackId}`,
  }))
  const body = { tracks }
  const response = await spotifyClient.delete(urn, body)
  return response.data
}

export const addTracksToPlaylist = async (playlistId, tracksId) => {
  const urn = `/playlists/${playlistId}/tracks`

  const uris = tracksId.map((trackId) => `spotify:track:${trackId}`)
  const body = { uris }

  const response = await spotifyClient.post(urn, body)
  return response.data
}

export const updatePlaylistDetails = async (playlistId, playlistData) => {
  const response = await spotifyClient.put(`/playlists/${playlistId}`, { playlistData })
  return response.data
}

export const reorderTracksInPlaylist = async (
  playlistId,
  insertBefore,
  rangeStart,
  rangeLength = 1
) => {
  const response = await spotifyClient.put(`/playlists/${playlistId}/tracks`, {
    insert_before: insertBefore,
    range_start: rangeStart,
    range_length: rangeLength,
  })
  return response.data
}

export const getPlaybackState = async () => {
  const response = await spotifyClient.get('/me/player')
  return response.data
}

export const startTrack = async (trackId) => {
  const response = await spotifyClient.put('/me/player/play', { uris: [`spotify:track:${trackId}`] })
  return response.data
}

export const pauseTrack = async () => {
  const response = await spotifyClient.put('/me/player/pause')
  return response.data
}

const endpoints = {
  addTracksToPlaylist,
  followArtists,
  followPlaylist,
  getAlbumById,
  getArtist,
  getArtists,
  getArtistsBulk,
  getPlaybackState,
  getPlaylist,
  getPlaylistById,
  getPlaylistTracks,
  getPlaylistTracksByPage,
  getPlaylistWithTracks,
  getTrack,
  getTrackFeatures,
  getUserPlaylists,
  getUserProfile,
  pauseTrack,
  removeTracksFromPlaylist,
  reorderTracksInPlaylist,
  searchItem,
  startTrack,
  unfollowArtists,
  updatePlaylistDetails
}

export default endpoints
